import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { PolicyholderService } from '@modules/policy/services/policy/policyholder.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { ClientSearchOptions } from '@shared/models/client-search-options.model';
import PolicyholderTabs from '@shared/models/policyholder.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policyholder',
  templateUrl: './policyholder.component.html',
  styleUrls: ['./policyholder.component.scss']
})
export class PolicyholderComponent implements OnInit {
  policyholderForm: FormGroup;
  nodeId: string;
  shouldHideClientField: boolean;
  policyId: number;
  cachedValues;
  clientKey;
  options: ClientSearchOptions;
  @ViewChild('formComponent', { static: false }) formComponent: FormComponent;

  constructor(
    private PolicyholderService: PolicyholderService,
    private route: ActivatedRoute,
    private PolicyDataService: PolicyDataService
  ) { }

  ngOnInit() {
    this.policyId = Number(this.PolicyDataService.getPolicyID());
    this.options = {
      sectionTitle: 'Contact Search',
      objectName: 'contact',
      clientReturnKeys: {
        clientKey: 'PolicyholderISIContactKey',
        contactReturnKey: 'SearchContactSearchCd',
        addressKey: 'SearchContactAddressPostalTxt',
        nameKey: 'SearchContactNameTxt',
      },
      formFields: {
        formName: 'Policyholder',
        firstNameField: 'PolicyholderFirstNameTxt',
        lastNameField: 'PolicyholderLastNameTxt',
        companyNameField: 'PolicyholderCompanyNameTxt',
        postalCodeField: 'PolicyholderAddressPostalTxt'
      },
      service: this.PolicyholderService
    }
  }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  initForm(form: FormGroup) {
    this.nodeId = this.route.snapshot.params.formId;
    this.policyholderForm = form;
    this.appendFormToDataSource(form);
    this.setCachedFormValues();
    if (this.formComponent) {
      handleValidations(this.formComponent.formjson, this.policyholderForm);
    }
  }

  appendFormToDataSource(form: FormGroup) {
    this.PolicyholderService.dataSourceSubject.pipe(
      filter(data => {
        const node: PolicyholderTabs = data.find(e => e.id === Number(this.nodeId));
        return node && node.formGroup === undefined;
      })
    ).subscribe(data => {
      data.find(e => e.id === Number(this.nodeId)).formGroup = form;
      this.PolicyholderService.updateDataSource(data);
    });
  }

  setCachedFormValues() {
    this.cachedValues = this.PolicyDataService.getValueFromCache(this.nodeId);
    if (this.cachedValues && (this.cachedValues[this.options.clientReturnKeys.clientKey])) {
      this.clientKey = this.cachedValues[this.options.clientReturnKeys.clientKey];
    } else {
      this.clientKey = null;
    }
  }

  updateSubtabName(data) {
    const { PolicyholderDescriptionTxt } = data;
    this.PolicyholderService.dataSourceSubject.pipe(
      take(1)
    ).subscribe(data => {
      const nodeData = data.find(e => e.id === Number(this.nodeId));
      if (nodeData != null) {
        nodeData.$name = PolicyholderDescriptionTxt;
      }
      this.PolicyholderService.updateDataSource(data);
    });
  }

  setClientKey(newClientKey: string) {
    this.clientKey = newClientKey;
    this.PolicyDataService.getData(this.policyId, this.nodeId).subscribe((res) => {
      this.updateSubtabName(res.data);
    });
  }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { BillingService } from '@modules/billing/services/billing.service';

@Component({
  selector: 'app-payments-step',
  templateUrl: './payments-step.component.html',
  styleUrls: ['./payments-step.component.scss']
})
export class PaymentsStepComponent implements OnInit {
  confirmationForm: FormGroup;
  billingData;

  showPreauthCard = true;
  showDownpaymentCard = true;
  showErrors = false;
  isPreauthComplete = false;
  isDownpaymentComplete = false;

  @Output() billStep = new EventEmitter<string>();
  @Output() confirmStep = new EventEmitter<string>();
  @Output() saveBillingData = new EventEmitter<{ [s: string]: any }>();
  displayedColumns: string[] = ['date', 'amount'];

  constructor(
    private snackbar: MatSnackBar,
    public billingService: BillingService
  ) {
    this.confirmationForm = new FormGroup({
      BillingCreditCardAuthorizationInd: new FormControl(false),
      BillingPaymentConfirmationInd: new FormControl(false),
    });
  }

  ngOnInit() {
    this.updateVisibilityAndStates();
    this.billingService.stepperState.subscribe(() => {
      this.updateVisibilityAndStates();
    })
  }

  get f() { return this.confirmationForm.controls; }

  billingStep(): void {
    this.billStep.emit(this.confirmationForm.value);
  }

  confirmationStep(): void {
    if (this.canStepForward()) {
      this.confirmStep.emit(this.confirmationForm.value);
    } else {
      this.showErrors = true;
      this.showSnackbar('Ensure all data is valid before continuing');
    }
  }

  showSnackbar(message: string) {
    this.snackbar.open(message, null, {
      duration: 2000,
    });
  }

  updateVisibilityAndStates() {
    const { billingData, PAYMENT_TYPES } = this.billingService
    if (billingData.BillingPaymentTypeCd == PAYMENT_TYPES.invoice) {
      this.showPreauthCard = false;
      this.f.BillingCreditCardAuthorizationInd.setValue(true);

      const downpaymentAmt = parseInt(billingData.BillingDownpaymentAmt);
      if (downpaymentAmt == 0) {
        this.showDownpaymentCard = false;
        this.f.BillingPaymentConfirmationInd.setValue(true);
      }
    }

    if (billingData.BillingCreditCardAuthorizationInd) {
      this.f.BillingCreditCardAuthorizationInd.setValue(true);
      this.f.BillingCreditCardAuthorizationInd.disable();
    }
    if (billingData.BillingPaymentConfirmationInd) {
      this.f.BillingPaymentConfirmationInd.setValue(true);
      this.f.BillingPaymentConfirmationInd.disable();
    }
  }

  confirmBox(form) {
    this.f[form].setValue(true);
    this.f[form].disable();

    const body = {};
    body[form] = true;
    this.saveBillingData.emit(body);
  }

  canStepBack() {
    return !this.f.BillingCreditCardAuthorizationInd.value && !this.f.BillingPaymentConfirmationInd.value;
  }

  canStepForward() {
    return this.f.BillingCreditCardAuthorizationInd.value && this.f.BillingPaymentConfirmationInd.value;
  }

  formatDate(dateString: string): string {
    return new Date(dateString).toLocaleDateString('en-US',
      { year: 'numeric', month: 'long', day: 'numeric' });
  }
}

import { FilterService } from './../../services/filter.service';
import { Component, Input, OnInit } from '@angular/core';
import { NewPolicyModalComponent } from '../new-policy-modal/new-policy-modal.component'
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';


@Component({
  selector: 'dashboard-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  private searchField: string = '';
  private filterEventsSub: Subscription
  @Input() brokerages;
  constructor(
    private dialog: MatDialog, 
    private FilterService: FilterService,
    private policyService: PolicyCreationService
  ) { }

  ngOnInit() {
    this.searchField = this.policyService.searchValue
    this.filterEventsSub = this.FilterService.events.subscribe(({ clear }) => {
      if (clear) this.searchField = ""
    });
  }

  toggleFilters() {
    this.FilterService.toggle();
  }

  newPolicyOptions(): void {
    this.dialog.open(NewPolicyModalComponent, {
      data: this.brokerages,
      panelClass: 'new-policy-modal-container',
      autoFocus: false
    });
  }

  applyFilter() {
    if (!this.searchField) return;
    const searchText = this.searchField.trim().toLowerCase();
    this.policyService.searchValue = searchText;
    this.FilterService.newEvent({ searchText: true });
  }

  clearSearch() {
    this.searchField = '';
    this.policyService.searchValue = '';
    this.FilterService.newEvent({ searchText: true });
  }

  hasSearchValue() {
    return this.searchField && this.searchField != "";
  }

  ngOnDestroy() {
    if (this.filterEventsSub) this.filterEventsSub.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { feMetadata } from '@shared/models/feMetadata.model';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  readonly LOCAL_STORAGE_KEY = 'c8FEMetadata';
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) { }

  updateMetadata(): Promise<void> {
    return this.http.get<any>(`${this.config.apiUrl}/config`).toPromise()
      .then(response => {
        localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(response));
        return;
      });
  }

  getMetadata(): feMetadata {
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
  }

  getDefaultRoute(LOB: string, transactionType: string): string {
    return this.getMetadata().policyCreationModal.lobs.find((lob) => {
      return lob.lob == LOB && lob.transactionType == transactionType
    }).defaultRoute;
  }

  getAnalyticsMetaData() {
    return this.getMetadata().policyAnalytics;
  }

  getWorkbenchMetaData() {
    return this.getMetadata().workbench;
  }
  
  getPoliciesInForceMetaData() {
    return this.getMetadata().policiesInForce;
  }

  getQuickFiltersMetaData() {
    return this.getWorkbenchMetaData().quickFilters;
  }

  getWorkbenchTableConfig() {
    return this.getWorkbenchMetaData().tableDefinition;
  }

  getDashboardTabs() {
    return this.getMetadata().tabs
  }
}
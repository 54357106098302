import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DynamicDropdownService {

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }
  constructor(private http: HttpClient) { }

  private getSelectValues(quoteId, nodeId, lookupName) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/lookup`, { params: { quoteId, nodeId, lookupName } });
  }

  private fetchAllSelectValues(config) {
    const lookupNames = [];
    if (!Array.isArray(config)) return;
    for (const form of config) {
      if (form.parameters != null) {
        for (const field of form["parameters"]) {
          if (field["type"] == "yesno") {
            const lookupName = "YesNo";
            lookupNames.push(lookupName);
          } else if (field["type"] == "select" || field["type"] == "search-select") {
            const lookupName = field["lookup"];
            if (!lookupName) continue;
            lookupNames.push(lookupName);
          }
        }
      }
    }
    return lookupNames;
  }

  public appendSelectValuesToConfig(config, quoteId, nodeId) {
    const lookupList = this.fetchAllSelectValues(config);
    return this.getSelectValues(quoteId, nodeId, lookupList).pipe(map(res => {
      for (const form of config) {
        if (form.parameters != null) {
          for (const field of form["parameters"]) {
            if (field["type"] == "yesno") {
              if (!res["YesNo"]) continue;
              field["options"] = res["YesNo"];
            } else if (field["type"] == "select" || field["type"] == "search-select") {
              const lookupName = field["lookup"];
              if (!lookupName || !res[lookupName]) continue;
              field["options"] = res[lookupName];
            }
          }
        }
      }
    }));
  }
}

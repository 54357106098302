import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Resolve } from "@angular/router";
import { AssetFetcherService } from '@modules/policy/services/shared/asset-fetcher.service';
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AppResolver implements Resolve<any> {
  private renderer: Renderer2;
  private stylesApplied: boolean = false
  
  constructor(
    private assetFetcherService: AssetFetcherService,
    public rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  resolve(): Observable<any> | Promise<any> | any {
    return this.resolveGlobalStyles()
  }

  private resolveGlobalStyles() {
    if (this.stylesApplied) return;

    const name = 'globals'
    return this.assetFetcherService.getStylesheet(name).toPromise()
      .then(({ data }) => {
        const styleEl = this.renderer.createElement('style');
        const css = this.renderer.createText(data);
        this.renderer.appendChild(styleEl, css);
        this.renderer.setAttribute(styleEl, 'id', name);
        this.renderer.appendChild(document.head, styleEl);

        this.stylesApplied = true;
      });
  }
}